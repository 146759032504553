import React, { Fragment } from "react"
import Text from "../hoc/text"

import { GatsbyImage } from "gatsby-plugin-image"
import { FormattedMessage } from "react-intl"
import { Link } from "gatsby"

function Portfolio({trabajos, portfolioPage, lang}){

    return(
        <section className={portfolioPage ? 'projects-v8 projects-v8-page' : 'projects-v8'}>
            <div className="container">
                <div className="title-v8 text-center">
                    {portfolioPage ? 
                        <Fragment>
                            <h1>Portfolio</h1>
                            <h2><FormattedMessage id="portfolioTitle" defaultMessage="Nuestros proyectos" /></h2>
                        </Fragment>
                    : <Fragment>
                        <h2><FormattedMessage id="portfolioTitle" defaultMessage="Nuestros proyectos" /></h2>
					    <p><FormattedMessage id="portfolioSubtitle" defaultMessage="Estos son algunos de nuestros trabajos que más nos gusta destacar:" /></p>
                        </Fragment>
                }
				</div>
                <div className="row projects-sliders">
                    {trabajos.map(trabajo=>(
                        <div className="col-lg-4 col-md-6" key={trabajo.id}>
                            <div className="project-slide">
                                {trabajo.tieneFicha ?
                                <Link to={lang == 'en' ? ("/en/portfolio/"+trabajo.slug) : ("/portfolio/"+trabajo.slug)} style={{color:'#dd2569'}}>
                                    <div className="ribbon-1">
                                        <i className="lni lni-star-filled"></i>
                                    </div>
                                    <GatsbyImage
                                        alt={trabajo.title}
                                        image={trabajo.image[0].gatsbyImageData}
                                        width={600}
                                        loading="eager"
                                        backgroundColor="transparent"
                                        placeholder="tracedSVG"
                                    />
                                    <div className="project-slide-info">
                                        <h3>{trabajo.title}</h3>
                                        <Text content={trabajo.texto} />
                                        <p class="portfolio-link"><FormattedMessage id="portfolioLinkLabel" defaultMessage="Ver caso de éxito"/>  &rarr;</p>
                                    </div>
                                    <span className="category">{trabajo.tipoDeTrabajo.map(cat=>(
                                        <span key={cat.id}>{cat.name}</span>
                                    ))}</span>
                                </Link>
                                :
                                    <Fragment>
                                        <GatsbyImage
                                            alt={trabajo.title}
                                            image={trabajo.image[0].gatsbyImageData}
                                            width={600}
                                            loading="eager"
                                            backgroundColor="transparent"
                                            placeholder="tracedSVG"
                                        />
                                        <div className="project-slide-info">
                                            <h3>{trabajo.title}</h3>
                                            <Text content={trabajo.texto} />
                                        </div>
                                        <span className="category">{trabajo.tipoDeTrabajo.map(cat=>(
                                            <span key={cat.id}>{cat.name}</span>
                                        ))}</span>
                                        <a href={trabajo.web} title={`Web: `+trabajo.title} className="share" rel="nofollow noreferrer" target="_blank" aria-label={`Visitar `+trabajo.title}><i className="lni lni-share"></i></a>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    ))}

                </div>
            </div>
        </section>
    )
}

export default Portfolio